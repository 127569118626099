import JsFileDownloader from 'js-file-downloader';

export default () => {
  const downloadFile = fileBase64 => {
    console.log('data:application/pdf;base64,' + fileBase64);
    return new JsFileDownloader({
      url: 'data:application/pdf;base64,' + fileBase64,
      filename: 'curriculum.pdf'
    });
  };
  return {
    downloadFile
  };
};
